import { rgba, lighten } from 'polished'

export const theme = {
	alpha: {
		tertiary: (amount) => `rgba(148, 160, 170, ${amount})`,
	},
	color: {
		primary: '#00263A', // Pumptec Blue
		secondary: '#F5A800', // Pumptec Orange
		tertiary: '#94A0AA', // Pumptec Light Blue
		red: '#BE1600',
		green: '#00FF00',
		white: '#ffffff',
		chalk: '#E6E6E6',
		ash: '#CCCCCC',
		grey: '#797A7B',
		ele: '#707070',
		coal: '#333333',
		black: '#111111',
	},
	modify: {
		rgba: (colour, value) => rgba(theme.color[colour], value),
		lighten: (colour, value) => lighten(value, theme.color[colour]),
	},
	fontWeight: {
		light: '300',
		regular: '400',
		semi: '700',
		bold: '800',
	},
}
