module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5FB5QZS","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n\t\t\t\t\treturn {\n\t\t\t\t\t\tplatform: 'gatsby',\n\t\t\t\t\t\tpageType: window.pageType,\n\t\t\t\t\t}\n\t\t\t\t}"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pumptec","short_name":"pumptec","start_url":"/","background_color":"#00263A","theme_color":"#00263A","display":"minimal-ui","icon":"src/assests/images/pumptec-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5a8126cfe63aa540a0de6da0778f7adf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-176065509-1","anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
