import React from 'react'
import { ThemeProvider } from 'styled-components'

// Theme
import { GlobalStyle, theme } from './src/theme'

export const wrapRootElement = ({ element }) => (
	<ThemeProvider theme={theme}>
		<GlobalStyle />
		{element}
	</ThemeProvider>
)
